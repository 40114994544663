import React, { Component } from 'react';
import {Form, FormGroup, Label, Input, Button} from 'reactstrap';
import './contact.css';
import db from '../components/firebase';

export default class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            albums:[]
        }
    }


    submit = e => {
        e.preventDefault();
        if (this.state.message2 === "") {
          db.collection("messages").add({
            Name: this.state.name,
            Tel: this.state.tel,
            Email: this.state.email,
            Message: this.state.message,
          })
            .then(() => {
              this.ClearForm();
              this.setState({ submitted: true })
            })
            .catch((error) => {
              console.error("Error adding document: ", error);
              this.setState({ error: true })
            });
        }
      }

    render() {

        return (
            <div>
        
        <h1>Arrange A Quote</h1>
        <Form style={{ paddingBottom: 10, }}>
          <FormGroup style={{ paddingLeft: 10, paddingRight: 10 }}>
            <Label for="name">Name</Label>
            <Input type="text" name="name" id="name" onChange={this.handleChange} value={this.state.name} placeholder="Enter your name" />
          </FormGroup>
          <FormGroup style={{ paddingLeft: 10, paddingRight: 10 }}>
            <Label for="exampleEmail">Email</Label>
            <Input type="email" name="email" id="exampleEmail" onChange={this.handleChange} value={this.state.email} placeholder="Enter your email" />
          </FormGroup>
          <FormGroup style={{ paddingLeft: 10, paddingRight: 10 }}>
            <Label for="tel">Tel</Label>
            <Input type="tel" name="tel" id="tel" onChange={this.handleChange} value={this.state.tel} placeholder="Enter your contact number" />
          </FormGroup>
          <FormGroup style={{ paddingLeft: 10, paddingRight: 10 }}>
            <Label for="message">Message</Label>
            <Input type="textarea" rows={4} name="message" id="message" onChange={this.handleChange} value={this.state.message} placeholder="Have a question? Send us a message!" />
          </FormGroup>
          <Input type="text" id="message2" name="message2" onChange={this.handleChange} />
          <Button className="btnRk" onClick={e => { this.submit(e); e.preventDefault(); }} style={{ fontSize: 30 }}>Submit</Button>
        </Form>
      
            </div>
        )
    }
}

