import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import logo from './images/logoA2B.jpg';
import Routes from "./Routes";
import './App.css';
import Nav from './components/Navbar';
import Footer from './components/Footer';
import {Container, Row, Col} from 'reactstrap';
class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            albums:[]
        }
    }

    render() {
      const childProps = {

      };
        return (<div className="App">
          <Nav/>
          
            <Container>
            <Row className="heroBar">
                <img id="logo" src={logo} style={{ maxHeight: 250, maxWidth:"100%", float: "left", marginRight: 20 }} />
                <ul style={{ listStyleType: "none", textAlign:"left" }}>
                    <li><b>Contact Us:</b></li>
                    <li>Unit 13</li>
                    <li>Bridge Industrial Estate</li>
                    <li>Hot lane</li>
                    <li>Burslem</li>
                    <li>Stoke-on-Trent</li>
                    <li>ST6 2DL</li>
                    <li>01782 201932</li>
                    <li><b>Mon - Sat</b></li>
                    <li><b>8am - 6pm</b></li>
                </ul>

            </Row>
            <Row>
                <Col>
                    <hr />
                </Col>
            </Row>
<Routes />
</Container>
<Footer/>
</div>
        )
    }
}

export default withRouter(App);