import React, { Component } from 'react';
import { compose, withProps } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"


export default class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            albums:[]
        }
    }

    render() {
    
        return (
            <div>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2398.806644979617!2d-2.186345633060776!3d53.04181227056259!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNTPCsDAyJzMwLjUiTiAywrAxMScwNS4zIlc!5e0!3m2!1sen!2suk!4v1578254035873!5m2!1sen!2suk" width="600" height="450" frameborder="0" style={{border:0}} allowfullscreen=""></iframe>
            </div>
        )
    }
}

