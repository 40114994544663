import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import {Link as PageLink} from 'react-router-dom';
import './footer.css';

export default class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            albums: []
        }
    }

    GetYear(){
        var date = new Date().getFullYear()
        return(
          date
        )
      }

    render() {

        return (
            <footer className="footer" style={{ marginTop: 50 }}>
                <Row style={{ color: "white" }}>
                    <Col sm={{ size: 3, offset: 1 }}>
                        <ul className="footer-col" style={{ listStyleType: "none" }}>
                            <li style={{ fontSize: 20 }}><b>WHERE TO FIND US:</b></li>
                            <li>Unit 13</li>
                            <li>Bridge Industrial Estate</li>
                            <li>Hot lane</li>
                            <li>Burslem</li>
                            <li>Stoke-on-Trent</li>
                            <li>ST6 2DL</li>
                        </ul>
                    </Col>
                    <Col sm={{ size: 2 }}>
                        <ul className="footer-col" style={{ listStyleType: "none" }}>
                            <li style={{ fontSize: 20 }}><b>COMMERCIAL REPAIRS</b></li>
                            <li><PageLink to={{pathname:"/commercial"}}>Commercial Vehcile Repairs</PageLink></li>
                            <li><PageLink to={{pathname:"/commercial"}}>Ministry Inspections</PageLink></li>
                            <li><PageLink to={{pathname:"/commercial"}}>MOT Preperations</PageLink></li>
                            <li><PageLink to={{pathname:"/commercial"}}>Steam Cleaning and Presentation</PageLink></li>
                        </ul>
                    </Col>
                    <Col sm={{ size: 2 }}>
                        <ul className="footer-col" style={{ listStyleType: "none" }}>
                            <li style={{ fontSize: 20 }}><b>RECOVERY</b></li>
                            <li><PageLink to={{pathname:"/recovery"}}>24 Hour Breakdown Recovery</PageLink></li>
                            <li><PageLink to={{pathname:"/recovery"}}>Road Side Recovery</PageLink></li>
                        </ul>
                    </Col>
                    <Col sm={{ size: 2 }}>
                        <ul className="footer-col" style={{ listStyleType: "none" }}>
                            <li style={{ fontSize: 20 }}><b>SERVICING & REPAIRS</b></li>
                            <li><PageLink to={{pathname:"/servicingandrepairs"}}>Non Commercial Vehcile Repairs</PageLink></li>
                            <li><PageLink to={{pathname:"/recovery"}}>24 Hour Breakdown Recovery</PageLink></li>
                            <li><PageLink to={{pathname:"/servicingandrepairs"}}>MOT Preperations</PageLink></li>
                            <li><PageLink to={{pathname:"/servicingandrepairs"}}>Steam Cleaning and Presentation</PageLink></li>
                        </ul>
                    </Col>
                </Row>
                <Row style={{textAlign:"center"}}>
                    <Col md={12}>
                        <div style={{ fontSize: 20 }}>
                        <PageLink to={{pathname:"/termsandconditions"}}>Copyright &copy; S And I Commercial {this.GetYear()}</PageLink>
                        </div>
                    </Col>
                </Row>
                <Row style={{textAlign:"center"}}>
                    <Col>
                        <div style={{ fontSize: 20 }}>
                            Made with ❤ by <a style={{ textDecorations: "none", color: "inherit" }} href="https://www.mattyj.co.uk/">Matty J</a>
                        </div>
                    </Col>
                </Row>
            </footer>
        )
    }
}

