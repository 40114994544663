import React, { Component } from 'react';
import Carandtruck from '../images/carandtruck.jpg';
import Sanditruck from '../images/sanditruck.jpg';
import { Container, Row, Col, Card, CardText,CardBody } from 'reactstrap';

export default class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            albums:[]
        }
    }

    render() {

        return (
            <div>
            <Container>
            <Row>
                <Col style={{ textAlign: "left" }}>
                    <h1>Commercial</h1>
                </Col>
            </Row>
            <Row>
                <Col md={8}>
                    <Card id="boxshadow">
                        <CardBody>
                        <CardText style={{textAlign:"left"}}>
                                <img className='cardImage' src={Carandtruck} style={{float:"left", maxHeight:150, margin:10, marginLeft:15}}/>
                                We provide a cost effective solution for all of your commercial vehicle repair and maintenance requirements. From a small van to a 44 ton truck, the team at S&I has both the knowledge and experience, supported by the latest computerised diagnostic technology; to undertake all aspects of routine and preventative maintenance and repairs.
 <br/><br/>
 <img className='cardImage' src={Sanditruck} style={{float:"right", maxHeight:550, maxWidth:"100%", margin:10, marginLeft:15}}/>  We will also prepare your vehicle for the annual Ministry test, and present it at the test station. Our services also include an on site steam cleaning facility.
 
 In short..we offer a modern repair service, supported by the latest technology, but guided by old fashioned values

</CardText>


                        </CardBody>

                    </Card>
                </Col>
            </Row>
        </Container>
            </div>
        )
    }
}

