import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, CardText } from 'reactstrap';

export default class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            albums: []
        }
    }

    render() {

        return (
            <div>
                <Container>
                    <Row>
                        <Col style={{ textAlign: "left" }}>
                            <h1>Tyres</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={8}>
                            <Card id="boxshadow">
                                <CardBody>
                                    <CardText style={{ textAlign: "left" }}>
                                        We currently sell and fit a wide range of tyres for cars and vans.<br />
                                        Please contact us for a quote
                                            <br /><br />
                                        <b>Call us now on: 01782 201932</b>
                                    </CardText>


                                </CardBody>

                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

