import React, { Component } from 'react';
import EngineCloseUp from '../images/carenginecloseup.jpg';
import SandIVan from '../images/sandivan1.jpg';
import { Container, Row, Col, Card, CardBody, CardText } from 'reactstrap';

export default class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            albums:[]
        }
    }

    render() {

        return (
            <Container>
            <Row>
                <Col style={{ textAlign: "left" }}>
                    <h1>Servicing And Repairs</h1>
                </Col>
            </Row>
            <Row>
                <Col md={8}>
                    <Card id="boxshadow">
                        <CardBody>
                        <CardText style={{textAlign:"left"}}>
                                <img className='cardImage' src={EngineCloseUp} style={{float:"left", maxHeight:150, margin:10, marginLeft:15}}/>
                                At S & I, we understand just how important your car is to you...and we believe that the secret to reliable motoring is preventative maintenance and regular servicing. We have technicians who are experienced in all modern cars and in conjunction with our state of the art computerised diagnostic technology, we provide a complete service from a one off repair through to a comprehensive maintenance regime.
 <br/><br/>
 We pride ourselves on our high quality customer service backed up by our aim to always deliver great value with cost effective solutions.
With experienced mechanics who have successfully repaired all manner of cars and vans over the years, you can be sure you’ve chosen the right team when you come to S and I Commercial for all your maintenance services
<br/><br/><p><img className='cardImage' src={SandIVan} style={{float:"right", maxHeight:150, maxWidth:"100%", margin:10, marginLeft:15}}/>We can also get your vehicle prepared for it's MOT and get it presented at a certified dealer.
</p></CardText>


                        </CardBody>

                    </Card>
                </Col>
            </Row>
        </Container>
        )
    }
}

