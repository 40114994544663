import firebase from 'firebase/app'
import 'firebase/app';
import 'firebase/firebase-auth';
import 'firebase/firebase-firestore';
import 'firebase/firebase-storage';

var firebaseConfig = {
    apiKey: "AIzaSyCRmHrQueUc0qsljbmgnV3HZClL4N46Jw8",
    authDomain: "sandicommercials.firebaseapp.com",
    databaseURL: "https://sandicommercials.firebaseio.com",
    projectId: "sandicommercials",
    storageBucket: "sandicommercials.appspot.com",
    messagingSenderId: "972867738552",
    appId: "1:972867738552:web:1c749938952322ac1dcd4d",
    measurementId: "G-GPKW800HWN"
  };

  firebase.initializeApp(firebaseConfig);
  export const provider = new firebase.auth.GoogleAuthProvider();
  export const db = new firebase.firestore();
  //export const auth = firebase.auth();
  export default firebase;