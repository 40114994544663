import React, { Component } from 'react';

import carandtruck from '../images/carandtruck.jpg';
import carenginecloseup from '../images/carenginecloseup.jpg';
import brokencar from '../images/brokencar.jpg';
import { Jumbotron, Row, Col, Card, CardImg, CardTitle, CardSubtitle, CardText, Button, CardBody } from 'reactstrap';
import './home.css';
import {Link as PageLink} from 'react-router-dom';
export default class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            albums: []
        }
    }

    render() {

        return (<div>
            
            <Row>
                <Col md={3}>
                    <Card id="boxshadow">
                        <CardImg top width="100%" src={carandtruck} alt="Card image cap" />
                        <CardBody>
                            <CardTitle>Commercial</CardTitle>
                            <CardText>We repair all issues on commercial vehicles</CardText>
                            <PageLink to={{pathname:"/commercial"}}>  <Button>See More</Button></PageLink>
                        </CardBody>
                    </Card>
                </Col>
                <Col md={3}>
                    <Card id="boxshadow">
                        <CardImg top width="100%" src={carenginecloseup} alt="Card image cap" />
                        <CardBody>
                            <CardTitle>Non Commercial</CardTitle>
                            <CardText>We service non-commercial vehicles</CardText>
                            <PageLink to={{pathname:"/servicingandrepairs"}}> <Button>See More</Button></PageLink>
                        </CardBody>
                    </Card>
                </Col>
                <Col md={3}>
                    <Card id="boxshadow">
                        <CardImg top width="100%" src={brokencar} alt="Card image cap" />
                        <CardBody>
                            <CardTitle>24hr Recovery</CardTitle>
                            <CardText>24 Hour recovery available in the area</CardText>
                            <PageLink to={{pathname:"/recovery"}}>  <Button>See More</Button></PageLink>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
        )
    }
}

