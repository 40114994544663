import React from "react";
import { Switch } from "react-router-dom";
import Home from './containers/home.js';
import About from './containers/about.js';
import Contact from './containers/contact.js';
import Repairs from './containers/repairs.js';
import Recovery from './containers/recovery.js';
import Tyres from './containers/tyres.js';
import Sales from './containers/sales.js';
import ServicingAndRepairs from './containers/servicingandrepairs.js';
import TermsAndConditions from './containers/termsandconditions.js';
import AppliedRoute from "./components/AppliedRoutes";
import Commercial from './containers/commercial.js';

export default ({childprops}) =>

<Switch>
    <AppliedRoute path="/" exact component={Home} props={childprops} render={(props) => <Home {...props} ref=""/>}/>
    <AppliedRoute path="/about" exact component={About} props={childprops} />
    <AppliedRoute path="/contact" exact component={Contact} props={childprops} />
    <AppliedRoute path="/repairs" exact component={Repairs} props={childprops} />
    <AppliedRoute path="/recovery" exact component={Recovery} props={childprops} />
    <AppliedRoute path="/tyres" exact component={Tyres} props={childprops} />
    <AppliedRoute path="/sales" exact component={Sales} props={childprops} />
    <AppliedRoute path="/commercial" exact component={Commercial} props={childprops} />
    <AppliedRoute path="/servicingandrepairs" exact component={ServicingAndRepairs} props={childprops} />
    <AppliedRoute path="/termsandconditions" exact component={TermsAndConditions} props={childprops} />

</Switch>