import React, { Component } from 'react';
import { Container, Row, Col, Card, CardImg, CardTitle, CardSubtitle, CardText, Button, CardBody } from 'reactstrap';
import EngineCloseUp from '../images/carenginecloseup.jpg';
import SandIVans from '../images/sandivans.jpg';

export default class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            albums:[]
        }
    }

    render() {

        return (
            <div>
                <Container>
                <Row>
                    <Col style={{textAlign:"left"}}>
                        <h1>Repairs</h1>
                    </Col>
                </Row>
                <Row>
                    <Col md={8}>
                    <Card id="boxshadow">
                        <CardBody>
                            <CardText style={{textAlign:"left"}}>
                                <img src={EngineCloseUp} style={{float:"left", maxHeight:150, margin:10, marginLeft:15}}/>
  At S and I Commercial, we use the latest computer diagnostic machines and dealer level Mercedes diagnositcs on anything from a car to a van to a truck
to diagnose your vehicle and get it back on the road as soon as possible. <br/><br/>
We know that getting your car back on the road in excellent condition is what is important to you. We utilise the latest diagnostic tools to ensure that when we hand you your keys, you'll be confident that you're driving away in a safe and well maintained vechicle.
<br/><br/><p><img src={SandIVans} style={{float:"right", maxHeight:150, margin:10, marginLeft:15}}/>If you want to prevent an issue with your vehicle worsening or are concerned that something might be going wrong with it, our mechanics can assess your vehicle and quickly start work to find and fix the cause of any problems.
</p></CardText>

                            
                        </CardBody>
                        
                    </Card>
                    </Col>
                </Row>
                </Container>
            </div>
        )
    }
}

