import React, { Component } from 'react';
import BrokenCar from '../images/brokencar.jpg';
import {Container, Row, Col, Card, CardBody, CardText} from 'reactstrap';

export default class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            albums:[]
        }
    }

    render() {

        return (
            <div>
<Container>
                <Row>
                    <Col style={{textAlign:"left"}}>
                        <h1>Recovery</h1>
                    </Col>
                </Row>
                <Row>
                    <Col md={8}>
                    <Card id="boxshadow">
                        <CardBody>
                            <CardText style={{textAlign:"left"}}>
                                <img className='cardImage' src={BrokenCar} style={{float:"left", maxHeight:150, margin:10, marginLeft:15}}/>We understand that your car, van or truck will never break down at a convenient time. At S & I we always aim to fix your problem where possible, at the side of the road.<br/>
If this cannot be achieved, we will recover you to our fully equipped garage and complete the necessary repairs, and have you back on the road in the shortest possible time.
<br/><br/>
<b>Call us now on: 01782 201932</b>
  </CardText>

                            
                        </CardBody>
                        
                    </Card>
                    </Col>
                </Row>
                </Container>
            </div>
        )
    }
}

